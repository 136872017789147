import i18n from '@/i18n';

export async function sleep(time) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export function getUrlWithoutQuery() {
  const url = window.location.href.split('?')[0];
  return url;
}

export function getCookie(cookieName) {
  const cookie = {};
  document.cookie.split(';').forEach(function(el) {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}

export function getUrlParams() {
  const historyUrl = document.location.href.replace(/\/#/g, ''); // To parse and remove any '#' from the URL.
  return new URL(historyUrl).searchParams;
}

export function getUrlParam(name) {
  const params = getUrlParams();
  return params.get(name);
}

export function getErrorMessage(err) {
  const status = err?.response?.status,
    code = err?.response?.data?.code,
    detail = err?.response?.data?.detail;

  const statusMessages = {
    401: i18n.t('user_not_authenticated'),
    403: i18n.t('user_not_authorized'),
    500: i18n.t('internal_server_error'),
    default: code ? i18n.t(code) : detail || err?.message
  };

  return statusMessages[status] || statusMessages.default;
}

export function getRequiredPlaceholder(value) {
  return `${i18n.t(value)} (${i18n.t('required')})`;
}

export function getValueWithSquareMeters(value) {
  if (!value) return null;
  return `${value} m²`;
}

export function currency(amount) {
  if (!amount && amount !== 0) return '-';
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0, // Allow numbers with no decimal places
    maximumFractionDigits: 2 // Maximum of 2 decimal places
  }).format(amount);
}

export function percentage(number) {
  if (!number) return '-';
  return new Intl.NumberFormat(i18n.locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(number / 100);
}

export async function recaptchaSubmit() {
  const grecaptcha = window.grecaptcha;
  const token = await new Promise(resolve => {
    grecaptcha.ready(async () => {
      const token = await grecaptcha.execute(
        process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        { action: 'submit' }
      );
      resolve(token);
    });
  });
  // Returns the token that can be then passed to BE requests
  return { 'Recaptcha-Token': token };
}

export function getFormattedDate(date) {
  return new Date(date).toLocaleString(i18n.locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
}

export function getMapboxStreetName(value) {
  return (
    value?.properties?.context?.street?.name ||
    value?.properties?.context?.name ||
    value?.properties?.name ||
    ''
  );
}

export function getISODateWithoutZ(datetime) {
  // Returns the time formatted in ISO string without the timezone as the BE is not TZ aware.
  return datetime.toISOString().slice(0, -1); // Removes the Z
}

export function toCalendarDate(milliseconds) {
  return new Date(milliseconds)
    .toISOString() // Convert to ISO string format
    .split('T')[0]; // Returns the date in YYYY-MM-DD format
}
